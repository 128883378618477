<template>
  <div id="reset-password" class="uk-height-viewport">
    <div class="uk-flex uk-flex-center">
      <div style="width:480px">

        <div style="height:200px">
          <img class="uk-margin-large-top" src="@/assets/IE-Logo.png" height="220" width="448" alt="CMS3" />
        </div>
        <div class="custom-card-bg uk-card-body uk-box-shadow-xlarge">
          <h3 class="uk-card-title uk-margin-small-bottom">{{$t('Pages.ResetPassword.EnterNewPassword')}}</h3>
          <form @submit.prevent="resetPassword();" id="reset-password-form" class="uk-form-stacked uk-margin"> 
            <div class="uk-margin-top">

              <div class="uk-margin">
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon" uk-icon="icon: user"></span>
                    <input class="uk-input uk-disabled" type="email" :class="{ 'invalid-border': !email }" :placeholder="$t('Pages.ResetPassword.ValidEmailNotFound')" v-model="email">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!email" uk-icon="icon: warning"></span>
                  </div>
                </div>
              </div>

              <div class="uk-margin">
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon" uk-icon="icon: lock"></span>
                    <input autocomplete="new-password" class="uk-input" :class="{ 'invalid-border': errors.password }" :type="showPassword ? 'text' : 'password'" :placeholder="$t('Generic.Labels.NewPassword')" v-model="form.password"  @keyup="keymonitor($event)" @blur="errors.password = false" @focus="errors.password = false">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.password" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.password" >  {{errors.password}}</span>
                    <a @click="showOrHidePassword" href="#" v-if="!errors.password && form.password" class="uk-form-icon uk-form-icon-flip">
                      <img  v-if="!showPassword" src="@/assets/hide password_icon.svg" alt="hide" uk-svg style="height:22px; color: #999;"/>
                      <img v-if="showPassword" src="@/assets/show password_icon.svg" alt="show" uk-svg  style="height:22px; color: #999;"/>
                    </a>
                  </div>
                </div>
              </div>

              <div class="uk-margin">
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon" uk-icon="icon: lock"></span>
                    <input autocomplete="confirm-password" class="uk-input" :class="{ 'invalid-border': errors.confirmPassword }" :type="showConfirmPassword ? 'text' : 'password'" :placeholder="$t('Generic.Labels.ConfirmPassword')" v-model="form.confirmPassword" @keyup="keymonitor($event)" @blur="errors.confirmPassword = false" @focus="errors.confirmPassword = false">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.confirmPassword" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.confirmPassword" >  {{errors.confirmPassword}}</span>
                    <a @click="showOrHideConfirmPassword"  href="#" v-if="!errors.confirmPassword && form.confirmPassword"  class="uk-form-icon uk-form-icon-flip eye-icon">
                      <img  v-if="!showConfirmPassword" src="@/assets/hide password_icon.svg" alt="hide" uk-svg style="height:22px; color: #999;"/>
                      <img v-if="showConfirmPassword" src="@/assets/show password_icon.svg" alt="show" uk-svg  style="height:22px; color: #999;"/>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          <div class="uk-margin uk-flex uk-flex-center" >
            <div class="uk-margin-small-right">
              <button class="uk-button uk-button-default uk-flex-between uk-width-small uk-modal-close" @click.prevent="clearForm()" type="button">{{$t('Actions.Cancel')}}</button>
            </div>
            <div class="uk-margin-small-bottom">
              <input v-if="!isLoading" class="uk-button uk-button-primary" :value="$t('Pages.ResetPassword.ResetPassword')" type="submit" @click.prevent="resetPassword()" />
              <button v-else class="uk-button uk-button-primary uk-width-small" type="button" :disabled="isLoading"><div uk-spinner="ratio: 0.5"></div></button>
            </div>
          </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/util/Notification';

export default {
  name: 'ResetPassword',
  data () {
    return {
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      form: {
        password: '',
        confirmPassword: ''
      },
      errors: {
        password: false,
        confirmPassword: false
      }
    }
  },
  computed: {
    token () {
      return this.$route.query.validation
    },
    email () {
      return this.$route.query.email
    }
  },
  methods: {
    clearForm () {
      this.form.confirmPassword = this.form.password = '';
      this.$router.push({ name: 'Login' });
    },
    async resetPassword () {
      if (!this.validateForm() || this.isLoading) return
      this.isLoading = true
      const response = await this.$store.dispatch('resetPassword', { email: this.email, token: this.token, password: this.form.password })
      this.isLoading = false
      if (response.user) {
        Notification.showNotification(this.$t('Pages.ResetPassword.PasswordReset'), this.$t('Pages.ResetPassword.PasswordResetDesc').replace('{{email}}', this.email))
        this.clearForm();
        this.$router.replace({ name: 'Login' })
      } else {
        Notification.showNotification(this.$t('Pages.ResetPassword.PasswordResetFailed'), response?.message || response || this.$t('Pages.ResetPassword.ResetLinkExpiredDesc'), 'error')
      }
    },
    validateForm () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.validatePasswords()) return false
      if (!this.token || !this.email) {
        Notification.showNotification(this.$t('Pages.ResetPassword.InvalidResetLink'), this.$t('Pages.ResetPassword.InvalidResetLinkDesc'))
        return false
      } else if (!re.test(this.email)) {
        Notification.showNotification(this.$t('Pages.ResetPassword.InvalidResetLink'), this.$t('Pages.ResetPassword.InvalidResetLinkDesc'))
        return false
      }
      return true
    },
    validatePasswords () {
      if (!this.form.password || this.form.password?.length < 10) {
        this.errors.password = this.$t('Generic.Errors.Min10Chars');
        return false
      }
      else if (!this.form.confirmPassword.length) {
        this.errors.confirmPassword = this.$t('Generic.Errors.PasswordsShouldMatch');
        return false
      } else if (this.form.password !== this.form.confirmPassword) {
        this.errors.confirmPassword = this.$t('Generic.Errors.PasswordsShouldMatch')
        return false
      }
      return true
    },
    keymonitor ( event ) {
      if(event.key !== "Enter")
       {
         this.errors.password = false;
         this.errors.confirmPassword = false;
       }
    },
     showOrHidePassword () {
      this.showPassword=!this.showPassword;
    },
    showOrHideConfirmPassword () {
      this.showConfirmPassword=!this.showConfirmPassword;
    }
  }
}
</script>

<style scoped>
  .custom-card-bg {
    background: var(--app-secondary-color);
  }
</style>